<template>
	<div class="page">
		<div class="service_banner relative">
			<img class="img" :src="bannerObj.imageUrl || require('../assets/static/xmzy_bg.png')"/>
			<div class="title">
				<div class="fontsize48 colorwhite">{{bannerObj.title || ""}}</div>
				<div class="desc fontsize18 colorwhite">{{bannerObj.subtitle || ""}}</div>
			</div>
		</div>
		<!-- 条件查询 -->
		<div class="huaxue_prodockitem_box">
			<div class="formitem_box w1239">
				<el-form ref="formObj" :model="formObj" label-width="120px">
					<el-form-item label="交易方式：">
					    <div class="xqlx_list fontsize14">
							<!-- <div class="item hover">不限</div> -->
							<div class="item" @click="handlepayType(item.id)" :class="{'hover':formObj.payTypeId==item.id}" v-for="(item,index) in payList" :key="index">{{item.name}}</div>
						</div>
					</el-form-item>
					<el-form-item label="成熟度：">
					    <div class="xqlx_list fontsize14">
							<div class="item" @click="handlematurity(item.id)" :class="{'hover':formObj.maturityId==item.id}" v-for="(item,index) in maturityList" :key="index">{{item.name}}</div>
						</div>
					</el-form-item>
					<el-form-item label="技术类型：">
					    <div class="xqlx_list fontsize14">
							<div class="item" @click="handletechnologyType(item.id)" :class="{'hover':formObj.technologyTypeId==item.id}" v-for="(item,index) in technologyList" :key="index">{{item.name}}</div>
						</div>
					</el-form-item>
					  
				</el-form>
			</div>
		</div>
		<!-- 内容 -->
		<div class="prodockitem_search_box w1239">
			<div class="search_box">
				<div class="inputdiv">
					<input v-model="formObj.title" class="fontsize16" placeholder="请输入搜索内容"/>
				</div>
				<div class="searchbtn fontsize18" @click="handleComfirm()">搜索</div>
			</div>
		</div>
		<div class="prodocktech_datalist_box w1260">
			<div class="itemobj pointer" v-for="(item,index) in dataList" :key="index" @click="$util.routerPath('/proDockDetail',{uid:item.id,typeName:typeName})">
				<div class="imgdiv">
					<img :src="item.imgUrl || require('../assets/static/noimg.png')"/>
					<div class="hot fontsize16">HOT</div>
				</div>
				<div class="conbox">
					<div class="titlename clamp fontsize16">{{item.title}}</div>
					<div class="descbox fontsize13">
						<div class="text clamp">行业分类：{{item.industryType}}</div>
						<div class="text clamp">成熟度：{{item.maturity}}</div>
						<div class="text clamp">交易方式：{{item.payType}}</div>
						<div class="text clamp">技术类型：{{item.technologyType}}</div>
					</div>
				</div>
				<div class="pricetext fontsize18">¥{{item.price||"面议"}}</div>
			</div>
		</div>
		<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
		<div class="block30"></div>
		<div class="w1239 phonenone" style="text-align:right">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total">
		    </el-pagination>
		</div>
		<div class="w1239 pcnone" style="text-align:right">
		    <el-pagination
			      small
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next"
		          :total="total">
		    </el-pagination>
		</div>
		
		<div class="block58"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeName:"项目成果",
			dataList:[],
			currentPage:1,
			pageSize:8,
			total:0,
			formObj:{
				title:'',
				successFlag:0,//项目类型 0项目成果 1技术需求
				payTypeId:null,//交易方式，null是查询全部
				maturityId:null,//成熟度，null是查询全部
				technologyTypeId:null,//技术类型，null是查询全部
			},
			industryList:[],
			payList:[
				{
					id:null,
					name:"不限"
				}
			],//交易方式
			maturityList:[
				{
					id:null,
					name:"不限"
				}
			],//成熟度
			technologyList:[
				{
					id:null,
					name:"不限"
				}
			],//技术类型
			bannerObj:{}
		};
	},
	mounted: function() {
		//头图
		this.getBannerFuc(18).then(bannerObj => {
			//console.log(bannerObj.imageUrl)
			if(bannerObj&&bannerObj.imageUrl){
				this.bannerObj = bannerObj
			}
		});
		//交易方式
		this.getpayList()
        //成熟度
		this.getmaturityList()
		//技术类型
		this.gettechnologyList()
		//获取(项目资源)
		this.getProjectResource()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//行业分类
		getindustryList(){
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('projectResource/project-resource-industry/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.industryList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//交易方式
		getpayList() {
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('manager/project-resource-pay-type/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.payList = _this.payList.concat(records)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//成熟度
		getmaturityList() {
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('manager/project-resource-maturity/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.maturityList = _this.maturityList.concat(records)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//技术类型
		gettechnologyList() {
			var _this = this
			var params = {currentPage: 1,pageSize: 88}
			this.$http.post('manager/project-resource-technology-type/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.technologyList = _this.technologyList.concat(records)
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//交易方式
		handlepayType(type){
			this.formObj.payTypeId = type
			this.handleComfirm()
		},
		//成熟度
		handlematurity(type){
			this.formObj.maturityId = type
			this.handleComfirm()
		},
		//技术类型
		handletechnologyType(type){
			this.formObj.technologyTypeId = type
			this.handleComfirm()
		},
		//查询确认
		handleComfirm(){
			this.currentPage = 1
			this.getProjectResource()
		},
		//跳转页面
		handleCurrentChange(currentPage){
			//console.log(currentPage)
			this.currentPage = currentPage
			this.getProjectResource()
		},
		//获取(项目资源)
		getProjectResource() {
			var _this = this;
			var params = this.formObj 
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			this.$http.post('frontEnd/ProjectResource/getProjectResource', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item, index) => {
						//console.log(item)
						item["body"] = _this.$util.filtersText(item.resourceBody,58)
						if(item.imagesList&&item.imagesList!='[]'){
							try{
								item["imgUrl"] = JSON.parse(item.imagesList)[0]
							}catch(e){
								//TODO handle the exception
							}
						}
					})
					_this.dataList = records
					_this.total = res.data.total
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>